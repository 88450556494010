.carousel_demo {
    .p_carousel {
        .p_carousel_content {
            .p_carousel_item {
                .cardetails {
                    > .p_grid {
                        border: 1px solid #b3c2ca;
                        border-radius: 3px;
                        margin: .3em;
                        text-align: center;
                        padding: 2em 0 2.25em 0;
                    }
                }

                .car_data {
                    .car_title {
                        font-weight: 700;
                        font-size: 20px;
                        margin-top: 24px;
                    }

                    .car_subtitle {
                        margin: .25em 0 2em 0;
                    }

                    button {
                        margin-left: .5em;

                        &:first-child {
                            margin-left: 0;
                        }
                    }
                }
            }
        }

        &.custom_carousel {
            .p_carousel_dot_icon  {
                width: 16px !important;
                height: 16px !important;
                border-radius: 50%;
            }
        }

        &.p_carousel_horizontal {
            .p_carousel_content {
                .p_carousel_item {
                    &.p_carousel_item_start {
                        .cardetails {
                            > .p_grid {
                                margin-left: .6em;
                            }
                        }
                    }

                    &.p_carousel_item_end {
                        .cardetails {
                            > .p_grid {
                                margin-right: .6em;
                            }
                        }
                    }
                }
            }
        }
    }
}


.content_section {
    display: block;
    padding: 30px;
    background-color: #f5f7f8;

    &:first-of-type > div > span {
        line-height: 1.5em;
    }

    h2 {
        margin-top: 0;
    }

    &.introduction {
        color: #ffffff;

        .feature_intro {
            h1 {
                font-size: 28px;
                margin: 0 0 20px 0;
                display: block;
                text-align: left;
                color: #ffffff;
                font-weight: normal;
            }

            p {
                margin: 0;
                line-height: 2em;
                font-size: 16px;
            }

            a {
                border-radius: 3px;
                background-color: #b2ddea;
                color: #00475d;
                padding: 2px 4px;

                &:hover {
                    background-color: #d8eef4;
                }
            }
        }
    }

    &.implementation {
        background-color: #ffffff;

        > hr {
            margin-top: 24px;
            opacity: .3;
        }

        > h3 {
            font-weight: 400;
            margin-top: 30px;
            font-size: 18px;

            &:first-child {
                margin-top: 0px;
            }
        }

        h2 {
            font-weight: 400;
        }

        .p-col-12 > h3 {
            font-weight: 400;
            margin-top: 30px;
            font-size: 18px;
        }

        h3.first {
            margin-top: 0px;
        }

        &.implementation-charts {
            background-color: #FFFFFF;
        }

        p {
            margin: 0;
            line-height: 1.5;
        }
    }

    &.content-submenu {
        background-color: #20272a;
        padding: 15px 30px;
        border-bottom: 1px solid #1b81d7;

        ul {
            margin: 0;
            padding: 0;
            width: 100%;

            li {
                list-style: none;
                width: 20%;
                display: inline-block;
                margin-top: 5px;

                a {
                    color: #cac6c6;
                    display: block;
                    width: 90%;
                    border: 1px solid transparent;
                    padding: 6px 12px;
                    border-radius: 3px;

                    &:hover {
                        border: 1px solid #ffffff;
                        color: #ffffff;
                    }

                    &:focus {
                        outline: 0 none;
                        transition: box-shadow .3s;
                        box-shadow: 0 0 0 0.2em #00c4e8;
                    }
                }
            }
        }
    }

    &.documentation {
        h3 {
            color:  #333333;
            margin-top: 25px;
            margin-bottom: 0px;
            font-size: 22px;
            font-weight: normal;
        }

        h4 {
            text-align: center;
            font-weight: 700;
            font-size: 16px;
        }

        p {
            color:  #333333;
            line-height: 24px;
            margin: 10px 0;
        }

        .doc-tablewrapper {
            margin: 10px 0;
        }

        a {
            color: #2f4054;
            font-weight: bold;
        }

        .btn-viewsource {
            background-color: #444;
            padding: .5em;
            border-radius: 2px;
            color: #fff;
            font-weight: 700;
            margin: .5em 0;
            display: inline-block;

            &:hover {
                background-color: #595959;
            }

            i {
                margin-right: .25em;
                background-color: transparent;
                font-family: 'FontAwesome';
            }
        }

        i:not([class~="pi"]) {
            background-color: #fcf2a7;
            font-family: monaco, Consolas, Lucida Console, monospace;
            font-weight: bold;
            font-style: normal;
            font-size: 14px;
            padding: 2px;
        }

    }

    &.support {
        .support-image {
            background-color: #906C90;
            padding: 30px;
            color: #ffffff;
            text-align: left;

            .p-md-6:last-child {
                text-align: right;
            }

            h3, p {
                color: #ffffff;
            }
        }

        li {
            line-height: 1.5;
        }
    }

    .doc-table {
        border-collapse: collapse;
        width: 100%;

        th {
            background-color: #cce8f1;
            padding: 10px 14px;
            text-align: left;
            border: solid 1px #b7d0d8;
        }

        tbody{
            td {
                padding: 10px 14px;
                border: 1px solid #d4e1e3;
            }

            tr{
                &:nth-child(even) {
                    background-color: #f3fbfc;
                }
                &:nth-child(odd) {
                    background-color: #ffffff;
                }
            }
        }
    }
}