/*
 * jQuery FlexSlider v2.0
 * http://www.woothemes.com/flexslider/
 *
 * Copyright 2012 WooThemes
 * Free to use under the GPLv2 license.
 * http://www.gnu.org/licenses/gpl-2.0.html
 *
 * Contributing author: Tyler Smith (@mbmufffin)
 */

 
/* Browser Resets */
.flex-container a:active,
.flexslider a:active,
.imac-device a:active,
.flex-container a:focus,
.flexslider a:focus,
.imac-device a:focus  {outline: none;}
.slides,
.flex-control-nav,
.flex-direction-nav {margin: 0; padding: 0; list-style: none;} 

/* FlexSlider Necessary Styles
*********************************/ 
.flexslider, .imac-device {margin: 0; padding: 0;}
.flexslider .slides, .imac-device .slides{margin: 0; padding: 0;}
.flexslider .slides > li, .imac-device .slides > li {display: none; -webkit-backface-visibility: hidden;margin: 0; padding: 0;} /* Hide the slides before the JS is loaded. Avoids image jumping */
.flexslider .slides img, .imac-device .slides img {width: 100%; display: block;}
.flex-pauseplay span {text-transform: capitalize;}

/* Clearfix for the .slides element */
.slides:after {content: "."; display: block; clear: both; visibility: hidden; line-height: 0; height: 0;} 
html[xmlns] .slides {display: block;} 
* html .slides {height: 1%;}

/* No JavaScript Fallback */
/* If you are not using another script, such as Modernizr, make sure you
 * include js that eliminates this class on page load */
.no-js .slides > li:first-child {display: block;}


/* FlexSlider Default Theme
*********************************/
.flexslider, .imac-device {margin:0; background: none; position: relative; zoom: 1;}
.flex-viewport {max-height: 2000px; -webkit-transition: all 1s ease; -moz-transition: all 1s ease; transition: all 1s ease;}
.loading .flex-viewport {max-height: 300px;}
.flexslider .slides, .imac-device .slides {zoom: 1;}

.flexslider .slides .col{width:20%;float:left;position:relative;}
.carousel li {margin-right: 5px}


/* Direction Nav */
.flex-direction-nav {*height: 0;}
.flex-direction-nav a {width: 26px; height: 42px; padding:0;margin:-45px 0 0 0; display: block;position: absolute; top: 50%;z-index: 10; cursor: pointer; text-indent: -9999px; opacity: 1; -webkit-transition: all .3s ease;-webkit-transition: all 0.3s ease-in-out;-moz-transition: all 0.3s ease-in-out;-o-transition: all 0.3s ease-in-out;-ms-transition: all 0.3s ease-in-out;transition: all 0.3s ease-in-out;text-indent: -9999px; }
.flex-direction-nav .flex-next {background-repeat: no-repeat; background-position: center center; right:40px;opacity:0.5;border-radius:3px 0 0 3px;}
.flex-direction-nav .flex-prev {background-repeat: no-repeat; background-position: center center;left:40px;opacity:0.5;border-radius:0 3px 3px 0;}
.flex-direction-nav .flex-next:hover, .flex-direction-nav .flex-prev:hover {opacity: 1;}
.flex-direction-nav .flex-disabled {opacity: .3!important; filter:alpha(opacity=30); cursor: default;}

/* Control Nav */
.flex-control-nav {width: 100%; position: absolute; bottom: -120px;padding-right:25px; text-align: right;z-index:999px}
.flex-control-nav li {margin: 0 5px; padding:4px;border:5px solid rgba(51, 51, 51, 0.2);display: inline-block; zoom: 1; *display: inline;}
.flex-control-paging li a {overflow:hidden;width: 7px; height: 7px; display: block; cursor: pointer;text-indent:-9999px;background-color:none;}
.flex-control-paging li a:hover { background-color: rgba(51, 51, 51, 0.2); }
.flex-control-paging li a.flex-active { background: #000; background: rgba(0,0,0,0.9); cursor: default; }

.flex-control-thumbs {margin: 5px 0 0; position: static; overflow: hidden;}
.flex-control-thumbs li {width: 25%; float: left; margin: 0;}
.flex-control-thumbs img {width: 100%; display: block; opacity: .7; cursor: pointer;}
.flex-control-thumbs img:hover {opacity: 1;}
.flex-control-thumbs .flex-active {opacity: 1; cursor: default;}

#flexprimary .flex-control-nav{
	bottom: -60px;
}

#flexprimary .flex-control-nav li {
	border:5px solid rgba(255, 255, 255, 0.5);
}

#flexprimary .flex-control-paging li a:hover {
	background:rgba(255, 255, 255, 0.8);
}

#flexprimary .flex-control-paging li a.flex-active{
	background:rgba(255, 255, 255, 0.5);
}

/* Flex Caption Effect */
.FadeIn, .FromTop, .FromRight, .FromBottom, .FromLeft {opacity: 0; transition: all 0.7s ease-in-out; -webkit-transition: all 0.7s ease-in-out;}
.captionDelay1 {transition-delay: 0.1s; -webkit-transition-delay: 0.1s;}
.captionDelay2 {transition-delay: 0.2s; -webkit-transition-delay: 0.2s;}
.captionDelay3 {transition-delay: 0.3s; -webkit-transition-delay: 0.3s;}
.captionDelay4 {transition-delay: 0.4s; -webkit-transition-delay: 0.4s;}
.captionDelay5 {transition-delay: 0.5s; -webkit-transition-delay: 0.5s;}
.captionDelay6 {transition-delay: 0.6s; -webkit-transition-delay: 0.6s;}
.captionDelay7 {transition-delay: 0.7s; -webkit-transition-delay: 0.7s;}
.captionDelay8 {transition-delay: 0.8s; -webkit-transition-delay: 0.8s;}
.captionDelay9 {transition-delay: 0.9s; -webkit-transition-delay: 0.9s;}
.captionDelay10 {transition-delay: 1s; -webkit-transition-delay: 1s;}
.captionDelay11{transition-delay: 1.1s; -webkit-transition-delay: 1.1s;}
.captionDelay12 {transition-delay: 1.2s; -webkit-transition-delay: 1.2s;}

.flexslider li.flex-active-slide .FadeIn {opacity: 1;}
.flexslider li .FromTop {transform: translateY(-100%); -o-transform: translateY(-100%); -ms-transform: translateY(-100%); -webkit-transform: translateY(-100%);}
.flexslider li.flex-active-slide .FromTop {opacity: 1; transform: translateY(0); -o-transform: translateY(0%); -ms-transform: translateY(0); -webkit-transform: translateY(0);}
.flexslider li .FromBottom {transform: translateY(100%); -o-transform: translateY(100%); -ms-transform: translateY(100%); -webkit-transform: translateY(100%);}
.flexslider li.flex-active-slide .FromBottom {opacity: 1; transform: translateY(0); -o-transform: translateY(0); -ms-transform: translateY(0); -webkit-transform: translateY(0);}
.flexslider li .FromLeft {transform: translateX(-100%); -o-transform: translateX(-100%); -ms-transform: translateX(-100%); -webkit-transform: translateX(-100%);}
.flexslider li.flex-active-slide .FromLeft {opacity: 1; transform: translateX(0); -o-transform: translateX(0); -ms-transform: translateX(0); -webkit-transform: translateX(0);}
.flexslider li .FromRight {transform: translateX(100%); -o-transform: translateX(100%); -ms-transform: translateX(100%); -webkit-transform: translateX(100%);}
.flexslider li.flex-active-slide .FromRight {opacity: 1; transform: translateX(0); -o-transform: translateX(0); -ms-transform: translateX(0); -webkit-transform: translateX(0);}

