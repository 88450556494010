.carousel-demo {
    .p-carousel {
        .p-carousel-content {
            .p-carousel-item {
                .car-details {
                    > .p-grid {
                        border: 1px solid #b3c2ca;
                        border-radius: 3px;
                        margin: .3em;
                        text-align: center;
                        padding: 2em 0 2.25em 0;
                    }
                }

                .car-data {
                    .car-title {
                        font-weight: 700;
                        font-size: 20px;
                        margin-top: 24px;
                    }

                    .car-subtitle {
                        margin: .25em 0 2em 0;
                    }

                    button {
                        margin-left: .5em;

                        &:first-child {
                            margin-left: 0;
                        }
                    }
                }
            }
        }

        &.custom-carousel {
            .p-carousel-dot-icon  {
                width: 16px !important;
                height: 16px !important;
                border-radius: 50%;
            }
        }

        &.p-carousel-horizontal {
            .p-carousel-content {
                .p-carousel-item {
                    &.p-carousel-item-start {
                        .car-details {
                            > .p-grid {
                                margin-left: .6em;
                            }
                        }
                    }

                    &.p-carousel-item-end {
                        .car-details {
                            > .p-grid {
                                margin-right: .6em;
                            }
                        }
                    }
                }
            }
        }
    }
}