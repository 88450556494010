/*
* 	Owl Carousel Owl Demo Theme 
*	v1.3.3
*/

.owl-theme .owl-controls{
	text-align: center;
}

/* Styling Next and Prev buttons */

.owl-theme .owl-controls .owl-buttons div{
	position:absolute;
	color: #454545;
	display: inline-block;
	zoom: 1;
	*display: inline;/*IE7 life-saver */
	top:50%;
	margin:0;
	padding:0;
	-webkit-border-radius: 0;
	-moz-border-radius: 0;
	border-radius: 0;
	background-image:url(../img/direction_nav.png);
	background-color:rgba(103, 103, 103, 0.3);
	width: 45px;
	height: 45px;
	overflow:hidden;
	text-indent:-99px;
	z-index:9;
	filter: Alpha(Opacity=100);/*IE7 fix*/
	font-family: 'nunito', sans-serif;
	font-size:20px;
	font-weight:700;
}

.owl-theme .owl-controls .owl-buttons div.owl-prev{
	background-position:-45px 0;
	background-repeat:no-repeat;
	left:-10px;
}

.owl-theme .owl-controls .owl-buttons div.owl-prev:hover{
	background-position:0 0;
}

.owl-theme .owl-controls .owl-buttons div.owl-next{
	background-position:-90px 0;
	background-repeat:no-repeat;
	right:-10px;
}

.owl-theme .owl-controls .owl-buttons div.owl-next:hover{
	background-position:-135px 0;
}

/* Clickable class fix problem with hover on touch devices */
/* Use it for non-touch hover action */
.owl-theme .owl-controls.clickable .owl-buttons div:hover{
	filter: Alpha(Opacity=100);/*IE7 fix*/
	opacity: 1;
	text-decoration: none;
}

/* Styling Pagination*/
.image-slide.owl-theme .owl-controls{
	position:absolute;
	width:100%;
	bottom:10px;
}

.owl-theme .owl-controls .owl-page{
	display: inline-block;
	zoom: 1;
	*display: inline;/*IE7 life-saver */
	width: 25px;
	height: 25px;
	margin: 40px 7px 0 7px;
	filter: Alpha(Opacity=50);/*IE7 fix*/
	opacity: 1;
	-webkit-border-radius: 0;
	-moz-border-radius: 0;
	border-radius: 0;
	background:none;
	border:5px solid rgba(255, 255, 255, 0.2);
	-webkit-transition: all 0.3s ease-in-out;
	-moz-transition: all 0.3s ease-in-out;
	-o-transition: all 0.3s ease-in-out;
	-ms-transition: all 0.3s ease-in-out;
	transition: all 0.3s ease-in-out;
	z-index:10;
}
.owl-theme .owl-controls .owl-page span{
	display: block;
	width: 7px;
	height: 7px;
	margin: 4px;
	filter: Alpha(Opacity=50);/*IE7 fix*/
	opacity: 1;
	-webkit-border-radius: 0;
	-moz-border-radius: 0;
	border-radius: 0;
	background:none;
	-webkit-transition: all 0.3s ease-in-out;
	-moz-transition: all 0.3s ease-in-out;
	-o-transition: all 0.3s ease-in-out;
	-ms-transition: all 0.3s ease-in-out;
	transition: all 0.3s ease-in-out;
}

.owl-theme .owl-controls .owl-page.active span,
.owl-theme .owl-controls.clickable .owl-page:hover span{
	filter: Alpha(Opacity=100);/*IE7 fix*/
	opacity: 1;
}

.owl-theme .owl-controls.clickable .owl-page:hover{
	border:5px solid rgba(255, 255, 255, 0.95);
}

/* If PaginationNumbers is true */

.owl-theme .owl-controls .owl-page span.owl-numbers{
	height: auto;
	width: auto;
	color: #FFF;
	padding: 2px 10px;
	font-size: 12px;
	-webkit-border-radius: 30px;
	-moz-border-radius: 30px;
	border-radius: 30px;
}

/* preloading images */
.owl-item.loading{
	min-height: 150px;
}