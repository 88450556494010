  .customerBadge {
    border-radius: 2px;
    padding: .25em .5em;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 12px;
    letter-spacing: .3px;
   
}
    .customerBadgeNew {
        border-radius: 2px;
        padding: .25em .5em;
        text-transform: uppercase;
        font-weight: 700;
        font-size: 12px;
        letter-spacing: .3px;
        color:  rgb(26, 137, 228);
       
    }

    .customerBadgeUnqualified {
        border-radius: 2px;
        padding: .25em .5em;       
        text-transform: uppercase;
        font-weight: 700;
        font-size: 12px;
        letter-spacing: .3px;
        color:  #C63737;
       
    }

    .customerBadgeQualified {
        border-radius: 2px;
        padding: .25em .5em;
        text-transform: uppercase;
        font-weight: 700;
        font-size: 12px;
        letter-spacing: .3px;
        color:  #189420;
       
    }

    .statusQualified {
        background-color: #C8E6C9;
        color: #256029;
    }


    .statusUnqualified {
        background-color: #FFCDD2;
        color: #C63737;
    }

    .statusNegotiation {
        background-color: #FEEDAF;
        color: #8A5340;
    }

    .statusNew {
        background-color: #B3E5FC;
        color: #23547B;
    }

    .statusRenewal {
        background-color: #ECCFFF;
        color: #694382;
    }

    .statusProposal {
        background-color: #FFD8B2;
        color: #805B36;
    }

    .departmentCto {
        background-color: #e9286f;
        color: #ffffff;
    }